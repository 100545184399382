import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule, MatProgressSpinnerModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';

const sharedModules = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,

  // Angular Material Components
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatDividerModule,
  MatIconModule,
  MatDialogModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatCardModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatButtonToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatChipsModule,
  MatMenuModule,
  MatSortModule,
  MatProgressSpinnerModule,

  // ngxDateTime Picker
  NgxMaterialTimepickerModule,

  // Angular Layout
  FlexLayoutModule,

  // Json Schema Forms
  MaterialDesignFrameworkModule
];

@NgModule({
  imports: sharedModules,
  exports: sharedModules
})
export class SharedLibrariesModule { }
