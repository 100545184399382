import { MediaMarshaller } from '@angular/flex-layout';

const MediaMarshaller_updateElement = MediaMarshaller.prototype.updateElement;

MediaMarshaller.prototype.updateElement = function(element: HTMLElement, key: string, value: any) {
  if (key === 'layout-gap' && (value === null || value === undefined)) {
    value = '0px';
  }
  MediaMarshaller_updateElement.apply(this, [element, key, value]);
};
