import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApplicationStore } from '@app/core/store/application.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showLoadingModal = false;

  title = 'AliseeksAdminPortal';

  constructor(private store$: Store) { }

  ngOnInit(): void {
    this.store$.select(ApplicationStore.getShowLoadingModal).subscribe(
      showLoading => this.showLoadingModal = showLoading
    );
  }
}
