import { ApiSchema, ServiceError, WebResource } from '@app/core/api/api-schema.interface';
import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { API_CONFIGURATION_PROVIDER, ApiConfigurationProvider } from '@app/core/api/api-configuration-provider.interface';
import { ApiPaths } from '@app/core/api/api.utility';

const SERVICE_NAME = 'aliseeksMoment';

@Injectable()
export class AliseeksMomentSchema implements ApiSchema {

  constructor(private httpHandler$: HttpHandler,
              @Inject(API_CONFIGURATION_PROVIDER) private configurationProvider$: ApiConfigurationProvider) { }

  getHandler(): HttpHandler {
    return this.httpHandler$;
  }

  getHost(): string {
    return this.configurationProvider$.getConfiguration(SERVICE_NAME).hostname;
  }

  getProtocol(): string {
    return this.configurationProvider$.getConfiguration(SERVICE_NAME).protocol;
  }

  resolveError(res: HttpErrorResponse): ServiceError {
    return new ServiceError(SERVICE_NAME, res, new Error());
  }

  timeOffs(): WebResource {
    return {
      path: ApiPaths.of('v1', 'timeoffs')
    };
  }

  config(): WebResource {
    return {
      path: ApiPaths.of('v1', 'config')
    };
  }

  shifts(): WebResource {
    return {
      path: ApiPaths.of('v1', 'shifts')
    };
  }
}
