import { HttpErrorResponse, HttpHandler } from '@angular/common/http';

export interface ApiSchema {
  getHost(): string;
  getProtocol(): string;
  getHandler(): HttpHandler;
  resolveError(res: HttpErrorResponse): ServiceError;
}

export interface WebResource {
  path: string,
  method?: string,
  config?: HttpConfig;
}

export interface HttpConfig {
  userAuth?: boolean;
  refreshAuth?: boolean;
  randomHash?: boolean;
  midwayCompany?: boolean;
  alertOnError?: boolean;
}

export class HttpConfigType implements HttpConfig {
  public userAuth = false;
  public refreshAuth = true;
  public randomHash = false;
  public midwayCompany = true;
  public alertOnError = true;

  static create(update: Partial<HttpConfig>): HttpConfig {
    return <HttpConfig> { ...new HttpConfigType(), ...update };
  }
}

export class ServiceError implements Error {
  readonly stack: string;
  readonly name: string;
  readonly message: string;
  readonly service: string;
  readonly response: HttpErrorResponse;
  readonly error: any;
  readonly nativeError: Error;

  constructor(service: string, response: HttpErrorResponse, error: any) {
    this.service = service;
    this.response = response;
    this.error = error;
  }
}

export type AliseeksErrors = AliseeksError[];

export class AliseeksError {
  code: string;
  message: string;
}
