
export class ShowLoadingModal {
  static readonly type = '[App State] Show Loading Modal';
  constructor() { }
}

export class HideLoadingModal {
  static readonly type = '[App State] Hide Loading Modal';
  constructor() { }
}
