import { NgModule } from '@angular/core';
import { ApiModule } from '@app/core/api/api.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@app/core/store/store.module';
import { ServicesModule } from '@app/core/services/services.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    ApiModule,
    StoreModule,
    ServicesModule
  ]
})
export class CoreModule { }
