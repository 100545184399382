import { NgModule } from '@angular/core';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '../../../environments/environment';
import { UserStateStore } from '@app/core/store/user.store';
import { ApplicationStore } from '@app/core/store/application.store';
import { HttpStoreListener } from '@app/core/store/http.store-listener';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      ApplicationStore,
      UserStateStore
    ]),
    NgxsStoragePluginModule.forRoot({
      key: [],
      storage: StorageOption.LocalStorage
    }),

    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    })
  ],
  providers: [
    HttpStoreListener
  ]
})
export class StoreModule {
  constructor(httpStoreListener$: HttpStoreListener) { }
}
