
export const Routz = {
  Empty: '',

  /** Moment Constants **/
  Moment: 'moment',
  Crawlers: 'crawlers',
  Midway: 'midway',
  Search: 'search'
};
