import { Inject, Injectable } from '@angular/core';
import { ApiSchema, ServiceError, WebResource } from '@app/core/api/api-schema.interface';
import { HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { API_CONFIGURATION_PROVIDER, ApiConfigurationProvider } from '@app/core/api/api-configuration-provider.interface';
import { ApiPaths } from '@app/core/api/api.utility';

const SERVICE_NAME = 'aliseeksCrawlerScheduler';

@Injectable()
export class AliseeksCrawlerSchedulerSchema implements ApiSchema {

  constructor(private httpHandler$: HttpHandler,
              @Inject(API_CONFIGURATION_PROVIDER) private configurationProvider$: ApiConfigurationProvider) { }

  getHandler(): HttpHandler {
    return this.httpHandler$;
  }

  getHost(): string {
    return this.configurationProvider$.getConfiguration(SERVICE_NAME).hostname;
  }

  getProtocol(): string {
    return this.configurationProvider$.getConfiguration(SERVICE_NAME).protocol;
  }

  resolveError(res: HttpErrorResponse): ServiceError {
    return new ServiceError(SERVICE_NAME, res, new Error());
  }

  proxies(id: string = null): WebResource {
    const path = [ 'proxies' ];

    if (id) {
      path.push(id);
    }

    return {
      path: ApiPaths.of(...path)
    };
  }
}

export interface ProxyHost {
  id: string;
  status: string;
  proxyType: string;
  scheme: string;
  host: string;
  port: number;
  username: string;
  password: string;
  forceBasicAuthentication: boolean;
  poolSize: number;
  description: string;
  priority: number;
  tags: string[];
  group: string;
  heartbeat: string;
}
