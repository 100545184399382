import { Component, Input } from '@angular/core';

@Component({
  selector: 'a-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: [
    './loading-modal.component.scss'
  ]
})
export class LoadingModalComponent {

  @Input()
  show: boolean;
}
