import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class HttpSnackbarService {
  constructor(private snackBar$: MatSnackBar) { }

  snackOnHttp<T>(): (a: Observable<T>) => Observable<T> {
    return tap(http => this.httpSuccessfulSnack(), error => this.httpErrorSnack());
  }

  private httpSuccessfulSnack() {
    this.snackBar$.open('👍 That looked like it worked.', 'Close',
      {
        duration: 1500
      });
  }

  private httpErrorSnack() {
    this.snackBar$.open('😭 That did not work, contact me!', 'Close',
      {
        duration: 1500
      });
  }
}
