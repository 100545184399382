import { User } from '@app/core/store/user.store';

export class UserLogin {
  static readonly type = '[User] Login';
  constructor(public payload: UserLoginRequest) { }
}

export class UserLoginSuccess {
  static readonly type = '[User] Login Success';
  constructor(public payload: User) { }
}

export interface UserLoginRequest {
  username: string;
  password: string;
}
