import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CustomApiBaseService } from '@app/core/api/custom-api-base.service';
import { AliseeksMidwaySchema } from '@app/core/api/aliseeks-midway.schema';
import { Observable } from 'rxjs';
import { User } from '@app/core/store/user.store';
import { map } from 'rxjs/operators';

@Injectable()
export class UserAuthenticationApiService extends CustomApiBaseService<AliseeksMidwaySchema> {
  constructor(schema: AliseeksMidwaySchema) {
    super(schema);
  }

  authenticateEmployee(username: string, password: string): Observable<User> {
    return this.post<AuthenticationResponse>(s => s.authEmployee(), { username, password })
      .pipe(map(this.mapAuthenticationResponse));
  }

  private mapAuthenticationResponse(response: AuthenticationResponse): User {
    return { authorizationToken: response.token };
  }
}

export interface AuthenticationResponse {
  token: string;
  refreshToken: string;
  refreshExpirationMinutes: number;
  expirationMinutes: number;
  expiration: string;
  roles: string[];
}
