import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserLogin, UserLoginSuccess } from '@app/core/store/user.actions';
import { UserAuthenticationApiService } from '@app/core/services/user-authentication-api.service';
import { switchMap } from 'rxjs/operators';

export interface UserState {
  user: User;
}

export interface User {
  authorizationToken: string;
}

@State<UserState>({
  name: 'user',
  defaults: {
    user: null
  }
})
export class UserStateStore {

  @Selector()
  static getUser(state: UserState) {
    return state.user;
  }

  constructor(private userApi$: UserAuthenticationApiService) { }

  @Action(UserLogin)
  userLogin({ dispatch }: StateContext<UserState>, { payload }: UserLogin) {
    return this.userApi$.authenticateEmployee(payload.username, payload.password)
      .pipe(switchMap(response => dispatch(new UserLoginSuccess(response))));
  }

  @Action(UserLoginSuccess)
  userLoginSuccess({ getState, setState }: StateContext<UserState>, { payload }: UserLoginSuccess) {
    const state = getState();
    return setState({
      ...state,
      user: payload
    });
  }
}
