import { NgModule } from '@angular/core';
import { API_CONFIGURATION_PROVIDER } from '@app/core/api/api-configuration-provider.interface';
import { ApiEnvironmentConfigurationProvider } from '@app/core/api/api-environment-configuration-provider';
import { AliseeksMomentSchema } from '@app/core/api/aliseeks-moment.schema';
import { AliseeksMidwaySchema } from '@app/core/api/aliseeks-midway.schema';
import { AliseeksCrawlerSchedulerSchema } from '@app/core/api/aliseeks-crawler-scheduler.schema';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationHttpInterceptor } from '@app/core/api/authentication-http.interceptor';
import { AliseeksSearchSchema } from '@app/core/api/aliseeks-search.schema';

@NgModule({
  providers: [
    { provide: API_CONFIGURATION_PROVIDER, useClass: ApiEnvironmentConfigurationProvider },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationHttpInterceptor, multi: true },

    AliseeksMomentSchema,
    AliseeksMidwaySchema,
    AliseeksCrawlerSchedulerSchema,
    AliseeksSearchSchema
  ]
})
export class ApiModule {

}
