import {ApiConfiguration, ApiConfigurationProvider} from '@app/core/api/api-configuration-provider.interface';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class ApiEnvironmentConfigurationProvider implements ApiConfigurationProvider {

  isProduction(): boolean {
    return environment.production;
  }

  getConfiguration(service: string): ApiConfiguration {

    if (!environment.apis || !environment.apis[service]) {
      throw new Error('Could not find configuration for service ' + service);
    }

    return environment.apis[service];
  }
}
