import { Injectable } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { ActionEvent, ActionEventStatus, getHttpAction } from '@app/core/store/action-event.types';
import { filter } from 'rxjs/operators';
import { HideLoadingModal, ShowLoadingModal } from '@app/core/store/application.actions';

@Injectable()
export class HttpStoreListener {
  constructor(private actions$: Actions,
              private store$: Store) {
    this.actions$.pipe(
      filter(actionEvent => this.filterHttpAction(actionEvent))
    ).subscribe(httpEvent => this.handleHttpAction(httpEvent));
  }

  filterHttpAction(event: ActionEvent) {
    const httpAction = getHttpAction(event.action);
    return !!httpAction;
  }

  handleHttpAction(event: ActionEvent) {
    const completedStatus = [ ActionEventStatus.Successful, ActionEventStatus.Errored ];
    if (completedStatus.indexOf(event.status) > -1) {
      this.store$.dispatch(new HideLoadingModal());
    } else {
      this.store$.dispatch(new ShowLoadingModal());
    }
  }
}
