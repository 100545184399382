

export const ActionEventStatus = {
  Successful: 'SUCCESSFUL',
  Errored: 'ERRORED'
};

export interface ActionEvent {
  action: any;
  status: string;
}

export interface HttpAction {
  success?: string;
  failure?: string;
  loading?: boolean;
}

export function getHttpAction(action: any): HttpAction {
  return action.constructor.http as HttpAction;
}

export function getCascadeAction(action: any): any[] {
  return action.constructor.cascadeAction;
}
