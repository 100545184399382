import { NgModule } from '@angular/core';
import { AliexpressCdnImagePipe } from '@shared/pipes/aliexpress-cdn-image.pipe';

const pipes = [
  AliexpressCdnImagePipe
];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {

}
