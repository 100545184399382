import {HttpHeaders, HttpRequest} from '@angular/common/http';
import {HttpConfig, HttpConfigType, WebResource} from '@app/core/api/api-schema.interface';

export const ApiPaths = {
  of(...elements: string[]) {
    return elements.join('/');
  },
  host(protocol: string, hostname: string) {
    return `${protocol}://${hostname}`;
  },
  get(path: string): WebResource {
    return {
        path: path,
        method: 'get',
        config: new HttpConfigType()
    };
  },
  post(path: string): WebResource {
    return {
      path: path,
      method: 'get',
      config: new HttpConfigType()
    };
  }
};
