import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HideLoadingModal, ShowLoadingModal } from '@app/core/store/application.actions';

export interface ApplicationState {
  showLoadingModal: boolean;
}

@State<ApplicationState>({
  name: 'app',
  defaults: {
    showLoadingModal: false
  }
})
export class ApplicationStore {

  @Selector()
  static getShowLoadingModal(state: ApplicationState) {
    return state.showLoadingModal;
  }

  @Action(ShowLoadingModal)
  showLoadingModal({ getState, setState }: StateContext<ApplicationState>) {
    return setState({
      ...getState(),
      showLoadingModal: true
    });
  }

  @Action(HideLoadingModal)
  hideLoadingModal({ getState, setState }: StateContext<ApplicationState>) {
    return setState({
      ...getState(),
      showLoadingModal: false
    });
  }
}
