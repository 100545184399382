import { NgModule } from '@angular/core';
import { SharedLibrariesModule } from '@shared/shared-libraries.module';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';

const modules = [
  SharedComponentsModule,
  SharedPipesModule,
  SharedLibrariesModule
];

@NgModule({
  imports: modules,
  exports: modules
})
export class SharedModule { }
