import { NgModule } from '@angular/core';
import { HttpSnackbarService } from '@app/core/services/http-snackbar.service';
import { UserAuthenticationService } from '@app/core/services/user-authentication.service';
import { UserAuthenticationApiService } from '@app/core/services/user-authentication-api.service';

@NgModule({
  providers: [
    HttpSnackbarService,
    UserAuthenticationService,
    UserAuthenticationApiService
  ]
})
export class ServicesModule { }
