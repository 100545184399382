import { NgModule } from '@angular/core';
import { NavBarComponent } from '@shared/components/nav-bar/nav-bar.component';
import { SharedLibrariesModule } from '@shared/shared-libraries.module';
import { NavMenuNestedListItemComponent } from '@shared/components/nav-menu-nested-list-item/nav-menu-nested-list-item.component';
import { LoadingModalComponent } from '@shared/components/loading-modal/loading-modal.component';

const components = [
  NavBarComponent,
  NavMenuNestedListItemComponent
];

const entryComponents = [
  LoadingModalComponent
];

@NgModule({
  imports: [
    SharedLibrariesModule
  ],
  entryComponents: entryComponents,
  declarations: [
    ...components,
    ...entryComponents
  ],
  exports: [
    ...components,
    ...entryComponents
  ]
})
export class SharedComponentsModule { }
