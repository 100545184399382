import { ApiSchema, ServiceError, WebResource } from '@app/core/api/api-schema.interface';
import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { API_CONFIGURATION_PROVIDER, ApiConfigurationProvider } from '@app/core/api/api-configuration-provider.interface';
import { ApiPaths } from '@app/core/api/api.utility';

const SERVICE_NAME = 'aliseeksSearch';

@Injectable()
export class AliseeksSearchSchema implements ApiSchema {

  constructor(private httpHandler$: HttpHandler,
              @Inject(API_CONFIGURATION_PROVIDER) private configurationProvider$: ApiConfigurationProvider) { }

  getHandler(): HttpHandler {
    return this.httpHandler$;
  }

  getHost(): string {
    return this.configurationProvider$.getConfiguration(SERVICE_NAME).hostname;
  }

  getProtocol(): string {
    return this.configurationProvider$.getConfiguration(SERVICE_NAME).protocol;
  }

  resolveError(res: HttpErrorResponse): ServiceError {
    return new ServiceError(SERVICE_NAME, res, new Error());
  }

  adminImageRequests(): WebResource {
    return {
      path: ApiPaths.of('admin', 'imageSearches')
    };
  }

  adminSearchRequests(): WebResource {
    return {
      path: ApiPaths.of('admin', 'searches')
    };
  }
}

export interface ImageSearchRequest {
  aeImageCdnKey: string;
  locale: string;
  currency: string;
  creationDate: string;
  selectedCategory: string;
  numberOfItems: number;
}

export interface SearchRequest {
  request: {
    text: string,
    category: number;
    currency: string;
    sort: string;
    sortDirection: string;
    itemRating: Range<number>;
    quantity: Range<number>
    price: Range<number>;
    orders: Range<number>;
    freightTypes: string[];
    flags: {
      freeShipping: boolean;
    };
    skip: number;
    limit: number;
  };
}

export interface Range<T> {
  from: T;
  to: T;
}
