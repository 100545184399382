import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserLogin } from '@app/core/store/user.actions';
import { User, UserStateStore } from '@app/core/store/user.store';

@Injectable()
export class UserAuthenticationService {
  constructor(private store$: Store) { }

  authenticateEmployee(username = 'original', password = 'original') {
    return this.store$.dispatch(new UserLogin({ username, password }));
  }

  getUser(): User {
    return this.store$.selectSnapshot(UserStateStore.getUser);
  }
}
