import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Routz } from '@app/routing.constants';
import { UserAuthenticationResolver } from '@app/core/routing/user-authentication.resolver';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: Routz.Moment,
        loadChildren: () => import('app/moment/moment.module').then(m => m.MomentModule)
      },
      {
        path: Routz.Crawlers,
        loadChildren: () => import('app/crawlers/crawlers.module').then(m => m.CrawlersModule)
      },
      {
        path: Routz.Midway,
        loadChildren: () => import('app/midway/midway.module').then(m => m.MidwayModule)
      },
      {
        path: Routz.Search,
        loadChildren: () => import('app/aliseeks-search/aliseeks-search.module').then(m => m.AliseeksSearchModule)
      },
      {
        path: '**',
        redirectTo: Routz.Moment
      }
    ],
    resolve: {
      user: UserAuthenticationResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    UserAuthenticationResolver
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
