import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAuthenticationService } from '@app/core/services/user-authentication.service';
import { Injectable } from '@angular/core';
import { User } from '@app/core/store/user.store';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
  constructor(private userService$: UserAuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.userService$.getUser();
    return next.handle(this.applyAuthorization(req, user));
  }

  private applyAuthorization(request: HttpRequest<any>, user: User): HttpRequest<any> {
    if (!user) {
      return request;
    }

    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + user.authorizationToken)
    });
  }
}
