import { InjectionToken } from '@angular/core';

export const API_CONFIGURATION_PROVIDER = new InjectionToken<ApiConfigurationProvider>('apiConfigurationProvider');

export interface ApiConfigurationProvider {
  getConfiguration(service: string): ApiConfiguration;
  isProduction(): boolean;
}

export interface ApiConfiguration {
  hostname: string;
  protocol: string;
}
