import { Component } from '@angular/core';
import { NavItem } from '@shared/components/nav-bar/nav-bar.models';

@Component({
  selector: 'a-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: [ './nav-bar.component.scss' ]
})
export class NavBarComponent {
  navItems: NavItem[] = [
    {
      displayName: 'Moment',
      iconName: 'shopping-basket',
      children: [
        {
          displayName: 'Schedule',
          route: '/moment/schedule',
          iconName: 'calendar_today'
        },
        {
          displayName: 'Shifts',
          route: '/moment/shifts',
          iconName: 'work'
        }
      ]
    },
    {
      displayName: 'Crawler',
      iconName: 'whatshot',
      // language
      children: [
        {
          displayName: 'Proxies',
          route: '/crawlers/proxies',
          iconName: 'device_hub'
        }
      ]
    },
    {
      displayName: 'Midway',
      iconName: 'lock',
      children: [
        {
          displayName: 'Features',
          route: '/midway/features',
          iconName: 'launch'
        }
      ]
    },
    {
      displayName: 'Search',
      iconName: 'search',
      children: [
        {
          displayName: 'Image Search Requests',
          route: '/search/imageSearches',
          iconName: 'camera'
        }
      ]
    }
  ];

  mobileNavOpen = false;

  toggleMobileNav() {
    this.mobileNavOpen = !this.mobileNavOpen;
  }
}
