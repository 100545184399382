import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UserAuthenticationService } from '@app/core/services/user-authentication.service';
import { User } from '@app/core/store/user.store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class UserAuthenticationResolver implements Resolve<User> {

  constructor(private userAuthentication$: UserAuthenticationService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.userAuthentication$.authenticateEmployee();
  }
}
