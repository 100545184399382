import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aliexpressCdnImage'
})
export class AliexpressCdnImagePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return `https://ae01.alicdn.com/kf/${value}_220x220.jpg`;
  }
}
