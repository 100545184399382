export const environment = {
  production: false,
  apis: {
    aliseeksMoment: {
      protocol: 'https',
      hostname: 'amazon-moment.aka.waka.run'
    },
    aliseeksMidway: {
      protocol: 'https',
      hostname: 'midway.aka.waka.run'
    },
    aliseeksCrawlerScheduler: {
      protocol: 'https',
      hostname: 'crawler-scheduler.aka.waka.run'
    },
    aliseeksSearch: {
      protocol: 'https',
      hostname: 'api.aliseeks.com'
    }
  }
};
