import {ApiSchema, WebResource} from '@app/core/api/api-schema.interface';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ApiPaths} from '@app/core/api/api.utility';
import {tap} from 'rxjs/internal/operators';

export class CustomApiBaseService<T extends ApiSchema> {

  private _http: HttpClient;

  get schema() { return this._schema; }

  constructor(private _schema: T) {
    this._http = new HttpClient(this._schema.getHandler());
  }

  protected get<R>(path: (schema: T) => WebResource, params?: any) {
    return this.request<R>(s => Object.create({ ...path(s), method: 'get' }), null, params);
  }

  protected post<R>(path: (schema: T) => WebResource, body?: any, params?: any ) {
    return this.request<R>(s => Object.create({ ...path(s), method: 'post' }), body ? body : {}, params);
  }

  protected delete<R>(path: (schema: T) => WebResource, body?: any, params?: any ) {
    return this.request<R>(s => Object.create({ ...path(s), method: 'delete' }), body ? body : {}, params);
  }

  protected request<R>(resourceFn: (schema: T) => WebResource,
             body?: any,
             params?: {string: string|string[]}) {
    const resource = resourceFn(this._schema);
    const url = this._resolveUrl(resource.path);
    return this._http.request<R>(resource.method, url, {
      body: body,
      params: params,
      headers: new HttpHeaders() // HttpConfigUtils.setConfig(new HttpHeaders(), resource.config)
    }).pipe(tap(a => a, err => this.handleError(err)));
  }

  private _resolveUrl(path: string) {
    return ApiPaths.of(ApiPaths.host(this._schema.getProtocol(), this._schema.getHost()), path);
  }

  private handleError(err) {
    if (err instanceof HttpErrorResponse) {
      const serviceError = this._schema.resolveError(err);
      const body = JSON.stringify(serviceError.response.error);
      const message = `ServiceError: ${serviceError.service} Status ${serviceError.response.status} Body ${body}`;
      const error = new Error(message);
      throw {
        ...serviceError,
        nativeError: error
      };
    }
  }
}
